.alarm-editor-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* затемнение фона */
  }
  
  .alarm-editor {
    background-color: black; /* Основной черный фон */
    width: 100%;
    height: 100%; /* Полная высота */
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 10px; /* Закругленные углы */
  }
  
  .editor-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: black;
    padding: 10px;
  }
  
  .editor-btn {
    background-color: black;
    color: white;
    padding: 5px 10px;
    border: 3px solid white; /* Белая рамка */
    cursor: pointer;
    transition: 0.3s;
    font-family: 'Press Start 2P', monospace;
  
    &:hover {
      background-color: white;
      color: black;
    }

    &.unactive {
        border: 3px solid gray;
        color: gray;
    }
  }
  
  .editor-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 10px;
  }
  
  input {
    width: calc(100% - 26px); /* Ширина с учетом отступов */
    margin-bottom: 10px;
    padding: 8px;
    background-color: black; /* Черный фон для инпутов */
    color: white; /* Белый текст */
    border: 3px solid white; /* Белая рамка */
    font-family: 'Press Start 2P', monospace;
  }
  
.token-choise {
    display: flex;
}

.token-choise__token {
    flex: 1;
    border: 3px dashed gray;
    padding: 8px;
    text-align: center;
    color: gray;
    cursor: pointer;
    // &:hover {
    //     border: 3px solid gray;
    // }
}

.token-choise__selected {
    border: 3px solid white;
    color: white;
}

.editor-select {
    padding: 8px;
    background: black;
    color: white;
    border: none;
    font-family: 'Press Start 2P', monospace;
}