@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  background-color: black;
  color: white;
  font-family: 'Press Start 2P', monospace;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
}

.alarm-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;

  .alarm-list {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .alarm-controller {
    border: 1px dashed white;
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
  }

  .alarm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px dashed white;

    &:hover {
      border: 2px solid white;
    }

    &-name {
      flex: 1;
      text-align: left;
      font-size: 14px;
    }

    .percent {
      flex: 1;
      text-align: center;
      color: white;
    }

    .toggle-btn {
      flex: 1;
      text-align: center;
      background-color: black;
      color: white;
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      border: 0.1px dashed white;
      font-family: 'Press Start 2P', monospace;
      transition: 0.3s;

      &.on {
        color: green;
      }

      &.off {
        color: red;
      }

      &:hover {
        border: 0.1px solid white;
      }
    }

    .status {
      font-weight: bold;

      &.on {
        color: green;
      }

      &.off {
        color: red;
      }
    }
  }

  .alarm-new {
    display: flex;
    justify-content: center;
    width: 100%;

    .alarm-add {
      background-color: black;
      color: white;
      border: 2px solid white;
      width: 100%;
      cursor: pointer;
      font-family: 'Press Start 2P', monospace;
      transition: 0.3s;
      padding: 20px;
    }
  }
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .alarm-container {
    width: 100%;
    max-width: none;
    padding: 5px;

    .alarm-controller {
      padding: 10px;

      .alarm-list {
        margin-bottom: 5px;

        .alarm {
          padding: 8px;

          &-name {
            font-size: 12px;
          }

          .toggle-btn {
            padding: 3px 8px;
          }
        }
      }
    }
  }
}


.center-container {
  text-align: center;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

input, div {
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent; /* Убирает серый фон при клике */
}

/* Для всех активных и фокусных состояний */
input:focus, div:focus, input:active, div:active {
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}